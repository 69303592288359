<template>
  <section class="hero is-fullheight">
    <vue-progress-bar />
    <Navbrand :logoutButton="false" />
    <div v-show="!loadingPage" class="container">
      <div class="box has-text-centered">
        <h1 class="title title-font">Morpher Has Launched</h1>
        <div class="has-text-left is-size-5">
          <div class="columns is-vcentered">
            <div class="column has-text-centered">
              <figure class="image img-spacing">
                <picture>
                  <source type="image/webp" srcset="@/assets/img/airdrop/app_shot_fade.webp">
                  <source type="image/png" srcset="@/assets/img/airdrop/app_shot_fade.png">
                  <img src="@/assets/img/airdrop/app_shot_fade.png" alt="Morpher app screenshot faded">
                </picture>
              </figure>
              <p
                class="has-text-left"
              >Our trading app is finally here! Join the app now to immediately redeem your airdrop crypto rewards.</p>
            </div>
          </div>
          <div class="columns is-vcentered is-centered">
            <div class="column is-8 has-text-centered">
              <button
                class="button big-button is-buy transition-faster mt-20"
                @click="register()"
              >Start Trading</button>
            </div>
          </div>
        </div>
      </div>

      <div class="box">
        <h2 class="title title-font is-size-5 mb-10">What happened to Airdrop?</h2>
        <p
          class="mb-10"
        >Airdrop is still here, it just moved to our trading app. Now when anyone signs up to trade on Morpher, they get free crypto funds in their account.</p>
        <h2 class="title title-font is-size-5 mb-10">I completed Airdrop, how do I get my crypto?</h2>
        <p>Thank you for joining the airdrop! Since you already provided your information, you can skip several steps when joining the trading app. Sign up for the trading app to redeem your crypto tokens.</p>
        <br>
        <div class="columns is-vcentered is-centered">
          <div class="column is-8 has-text-centered">
            <button
              class="button big-outlined-button transition-faster mt-20"
              @click="register()"
            >Redeem Your Airdrop Rewards</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Navbrand from "@/components/partials/Navbrand.vue";

export default {
	name: "AirdropStatus",
	components: {
		Navbrand,
	},
	data() {
		return {
			title: "Airdrop Status",
			redirectURL: "",
			loadingPage: false,
		};
	},
	async mounted() {
		this.redirectURL =
			(process.env.VUE_APP_APPLICATION_URL || "https://morpher.com/trade") +
			"/register";
		// setTimeout(this.countdownTimer, 1000);
	},
	methods: {
		// automatically redirect the user to the app register page when countdown reaches 0
		// countdownTimer() {
		// 	this.redirectTimeout -= 1;
		// 	if (this.redirectTimeout < 1) {
		// 		window.location.href = this.redirectURL;
		// 	} else {
		// 		setTimeout(this.countdownTimer, 1000);
		// 	}
		// },
		// user clicked signup button so redirect to the app register page.
		register() {
			window.location.href = this.redirectURL;
		},
	},
};
</script>

<style scoped>
.hero {
	background-color: #fbfafc;
}

.box {
	margin-top: 60px;
	width: 600px;
	padding: 1.5rem 3rem;
	-webkit-box-shadow: 0 10px 20px 0 rgba(182, 191, 210, 0.4);
	box-shadow: 0 10px 20px 0 rgba(182, 191, 210, 0.4);
	border-radius: 13px;
	border: 3px solid hsla(0, 0%, 100%, 0.6);
}

.is-size-8 {
	font-size: 14px !important;
}

.img-spacing {
	margin-top: -1.25rem;
	margin-bottom: 1rem;
}

@media only screen and (max-width: 768px) {
	.container {
		width: 100%;
	}
}
.input-button {
	border-radius: 5px;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
	border: none;
	background: #00c386;
	font-family: "Roboto", sans-serif;
	font-weight: 600 !important;
	color: #fff;
	transition-delay: 0s;
	transition-duration: 0.5s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
	outline: none;
	width: 100%;
}

.input-button:hover {
	cursor: pointer;
	outline: none;
	background: #00d492;
	color: #fff;
	box-shadow: 0px 1px 2px rgba(0, 161, 111, 0.2),
		0px 6px 12px rgba(0, 161, 111, 0.2);
	transform: translateY(0);
	transition-delay: 0s;
	transition-duration: 0.5s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@media only screen and (max-width: 768px) {
	.box,
	.faq-section {
		width: 90%;
		margin: 0 auto;
		margin-top: 20px;
	}
	.faq-section {
		margin-top: 30px;
	}
	.input-button,
	.cta-btn,
	.input {
		width: 100%;
	}
	.ml-10-tablet {
		margin-left: 0px;
	}
	.mt-15-mobile {
		margin-top: 15px;
	}
	.cta-btn.button.is-twitter,
	.cta-btn.button.is-telegram,
	.cta-btn.button.is-facebook {
		width: 100%;
	}
	.box-top {
		margin-bottom: inherit;
	}
}
@media only screen and (max-width: 400px) {
	.is-hidden-400 {
		display: inherit;
	}
}
</style>